const findNodeById = (nodes, id) => {
  for (let index = 0; index < nodes.length; index++) {
    const node = nodes[index];

    if (node.nodeId === id) {
      return node;
    }

    if (node.children) {
      const find = findNodeById(node.children, id);
      if (find) {
        return find;
      }
    }
  }

  return null;
};

export default findNodeById;
