import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { useApp } from 'src/hooks';

const styleImage = {
  display: 'flex',
  margin: 'auto',
  paddingBottom: 20,
  maxWidth: 300,
};

interface LogoProps {
  imageName: 'logo' | 'logo_header';
  linkTo?: string;
  testId?: string;
  width?: number | string;
  onClick?: () => void;
}

export default function Logo(props: LogoProps) {
  const { imageName, linkTo, testId, width, onClick } = props;
  const { context } = useApp();

  const alt = (imageName + '_alt') as 'logo_alt' | 'logo_header';

  const img = (
    <img src={context[imageName]} alt={context[alt]} data-testid={testId ?? `image-${imageName}`} width={width ?? 300} style={styleImage} />
  );

  if (onClick) {
    return (
      <Link href="#" onClick={onClick}>
        {img}
      </Link>
    );
  } else if (linkTo) {
    return (
      <Link to={linkTo ?? '/'} component={RouterLink} replace>
        {img}
      </Link>
    );
  } else {
    return <Link href="#">{img}</Link>;
  }
}
