import { format, parseISO } from 'date-fns';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { green, red } from '@mui/material/colors';

const DateTime =
  (field: string, formatStr = 'dd/MM/yyyy HH:mm:ss') =>
    (rowData: any) => {
      if (!rowData[field]) {
        return '';
      }

      const date = parseISO(rowData[field]);
      return format(date, formatStr);
    };

const Boolean = (field: string, options: { inverse?: boolean } = {}) =>
  function BooleanInner(rowData: any) {
    const { inverse = false } = options;
    const checkCondition = inverse ? !rowData[field] : rowData[field];

    return checkCondition ? <CheckIcon sx={{ color: green[500] }} /> : <ClearIcon sx={{ color: red[500] }} />;
  };

const RemoteTableRenderers = {
  DateTime,
  Boolean,
};

export default RemoteTableRenderers;
