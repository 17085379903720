// https://mui.com/customization/theme-components
import { Components } from '@mui/material';

const components: Components = {
  MuiToolbar: {
    styleOverrides: {
      root: {
        minHeight: '46px',
        '@media (min-width:600px)': {
          minHeight: '46px',
        },
      },
    },
  },

  MuiCard: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },

  MuiCardHeader: {
    styleOverrides: {
      root: {
        padding: '10px',
      },
    },
  },

  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: '10px',
        ':last-child': { padding: '10px' },
      },
    },
  },

  MuiTableCell: {
    styleOverrides: {
      root: {
        paddingTop: 2,
        paddingBottom: 2,
      },
    },
  },

  MuiBreadcrumbs: {
    styleOverrides: {
      ol: {
        display: 'flex',
        flex: 1,
        alignItems: 'flex-end',
      },
    },
  },

  MuiTextField: {
    defaultProps: {
      size: 'small',
      margin: 'dense',
      variant: 'outlined',
    },
  },

  MuiAutocomplete: {
    defaultProps: {
      size: 'small',
      // margin: 'dense',
      // variant: 'outlined',
    },
  },

  MuiChip: {
    defaultProps: {
      size: 'small',
      variant: 'outlined',
    },
  },

  MuiOutlinedInput: {
    defaultProps: {
      margin: 'dense',
      size: 'small',
    },
  },

  MuiFormControl: {
    defaultProps: {
      size: 'small',
      margin: 'dense',
      variant: 'outlined',
    },
  },
};

export default components;
