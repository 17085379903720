import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined';
import { Badge, IconButton } from '@mui/material';
import { useSound } from 'src/hooks';

const Alerts = function Alerts() {
  const { isEnable, setEnable } = useSound();
  const onClickCampana = () => setEnable(!isEnable);

  return (
    <IconButton color="inherit" onClick={onClickCampana} size="medium">
      <Badge badgeContent={0} color="secondary">
        {isEnable ? <NotificationsNoneOutlinedIcon /> : <NotificationsOffOutlinedIcon />}
      </Badge>
    </IconButton>
  );
};

export default Alerts;
