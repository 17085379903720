import { Alert, Box, Button, Typography } from '@mui/material';
import Logo from '../Logo';

export interface ErrorFallbackProps {
  error: any;
  resetErrorBoundary: () => void;
}

export default function ErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  return (
    <Box
      sx={{
        height: '60%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        flexDirection: 'column',
      }}
    >
      <Logo imageName="logo" width={100} />

      <Alert severity="error" icon={false}>
        <Typography variant="h6">¡¡¡ Ooops, ha ocurrido un error !!!</Typography>
        <Typography sx={{ mt: 2 }}>Recargue la página del navegador, borre la cookies y los datos del navegador y vuelva a intentarlo.</Typography>
        <Button variant="contained" onClick={resetErrorBoundary} sx={{ mt: 5 }}>
          Recargar navegador
        </Button>
      </Alert>
    </Box>
  );
}
