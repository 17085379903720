import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import SidebarNav from 'src/sidebar/Sidebar';
import Profile from './components/Profile/Profile';

const drawerWidth = 240;

// drawer: {
//   width: 240,
//   [theme.breakpoints.up('lg')]: {
//     marginTop: 64,
//     height: 'calc(100% - 64px)',
//   },
// },

const DrawerStyled = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' },
}));

interface SidebarProps {
  open: boolean;
  variant: 'persistent' | 'temporary';
}

const Sidebar = function Sidebar(props: SidebarProps) {
  const { open, variant } = props;

  return (
    <DrawerStyled anchor="left" open={open} variant={variant}>
      <Box
        sx={{
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          padding: 2,
        }}
      >
        <Profile />
        <Divider sx={{ margin: 2 }} />
        <SidebarNav />
      </Box>
    </DrawerStyled>
  );
};

export default Sidebar;
