import { DialogProps, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

interface DialogConfirmationProps extends DialogProps {
  open: boolean;
  title: string;
  message: string | React.ReactNode;
  onClose: (confirm: boolean) => void;
  disabled?: boolean;
  okText?: string;
}

const DialogConfirmation = function DialogConfirmation(props: DialogConfirmationProps) {
  const { open, title, message, onClose, disabled, okText, ...other } = props;

  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  if (!open) {
    return null;
  }

  return (
    <Dialog open={open} {...other}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{typeof message === 'string' ? <Typography>{message}</Typography> : message}</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} disabled={disabled}>
          Cancelar
        </Button>
        <Button onClick={handleOk} data-testid="ok" disabled={disabled}>
          {okText ?? 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfirmation;
