import xhr from './xhr';

export interface Lookup {
  [key: number]: string;
}

/**
 * Devuelve un lookup para una filtro de tabla desde la query de un select.
 * @param url url de la api para select
 */
export default async function lookupFromSelect(url: string): Promise<Lookup> {
  const response = await xhr.get(url);

  const lookup: Lookup = {};

  response.forEach((value: { id: number; nombre: string }) => {
    lookup[value.id] = value.nombre;
  });

  return lookup;
}
