import startsWith from 'lodash/startsWith';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link, colors } from '@mui/material';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import PermsGuard from 'src/components/authentication/perms-guard';

const ItemMenu = styled(ListItem)(() => ({
  display: 'flex',
  paddingTop: 0,
  paddingBottom: 0,
}));

const ItemMenuChildren = styled(ListItem)(() => ({
  display: 'flex',
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 28,
}));

const ButtonMenu = styled(Button)(({ theme }) => ({
  color: colors.blueGrey[800],
  padding: '10px 8px',
  justifyContent: 'flex-start',
  textTransform: 'none',
  letterSpacing: 0,
  width: '100%',
  fontWeight: theme.typography.fontWeightMedium,
}));

const IconLeft = styled('div')(({ theme }) => ({
  color: theme.palette.icon,
  width: 24,
  height: 24,
  display: 'flex',
  alignItems: 'center',
  marginRight: theme.spacing(1),
}));

const IconRight = styled('div')(({ theme }) => ({
  color: theme.palette.icon,
  width: 24,
  height: 24,
  marginLeft: 'auto',
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const HrefRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <Link {...props} />
  </div>
));

const Item = function Item(props) {
  const { title, href, icon, open, children, permissions, subItem, blank, testId } = props;
  const theme = useTheme();
  const location = useLocation();
  const [openItem, setOpenItem] = React.useState(startsWith(location.pathname, href) || open);
  const handleClick = () => setOpenItem(!openItem);
  const sxButtonMenu = { fontSize: theme.typography.pxToRem(16) };
  const iconNode = icon ? (
    <IconLeft>
      <i className={`fas ${icon} sidebar-icon`} />
    </IconLeft>
  ) : null;
  const testIdBtn = testId ?? `btn-item-${title.toLowerCase()}`;

  if (children) {
    return (
      <PermsGuard permissions={permissions}>
        <ItemMenu disableGutters key={href}>
          <ButtonMenu onClick={handleClick} sx={sxButtonMenu} data-testid={testIdBtn}>
            {iconNode}
            {title}
            <IconRight>{openItem ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconRight>
          </ButtonMenu>
        </ItemMenu>
        <Collapse in={openItem} timeout="auto" unmountOnExit key={`${href}_subitems`}>
          <List component="div" disablePadding>
            {children}
          </List>
        </Collapse>
      </PermsGuard>
    );
  }

  const ItemChildren = icon ? ItemMenu : ItemMenuChildren;

  return (
    <PermsGuard permissions={permissions}>
      <ItemChildren disableGutters>
        <ButtonMenu
          component={blank ? HrefRouterLink : CustomRouterLink}
          to={href}
          href={href}
          target={blank ? '_blank' : null}
          sx={subItem ? { paddingY: 1 } : sxButtonMenu}
          data-testid={testIdBtn}
        >
          {iconNode}
          {title}
        </ButtonMenu>
      </ItemChildren>
    </PermsGuard>
  );
};

Item.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  permissions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]).isRequired,
  icon: PropTypes.string,
  open: PropTypes.bool,
  subItem: PropTypes.bool,
  children: PropTypes.node,
  blank: PropTypes.bool,
};

Item.defaultProps = {
  icon: null,
  open: false,
  subItem: false,
  children: null,
  blank: false,
};

export default Item;
