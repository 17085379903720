import { Suspense, lazy, useMemo } from 'react';
import { PermsGuard } from 'src/components/';
import MainLayout from 'src/layouts/Main/Main';
import AuthGuard from './authentication/auth-guard';

interface ComponentRouteProps {
  view: string;
  layout?: () => JSX.Element;
}

const ComponentRoute = function ComponentRoute(props: ComponentRouteProps) {
  const { view, layout } = props;
  const Layout = layout || MainLayout;
  const Component = lazy(() => import(`src/views/${view}`));

  return (
    <Layout>
      <Suspense>
        <Component />
      </Suspense>
    </Layout>
  );
};

interface RouteLoaderProps {
  view: string;
  auth?: boolean;
  layout?: () => JSX.Element;
  permissions?: string | string[];
}

const RouteLoader = function RouteLoader(props: RouteLoaderProps) {
  const { view, layout, permissions, auth } = props;

  const node = useMemo(() => {
    if (!auth) {
      return <ComponentRoute view={view} layout={layout} />;
    }

    if (permissions) {
      return (
        <AuthGuard>
          <PermsGuard permissions={permissions} redirect403>
            <ComponentRoute view={view} layout={layout} />
          </PermsGuard>
        </AuthGuard>
      );
    }
    return <ComponentRoute view={view} layout={layout} />;
  }, [layout, permissions, view, auth]);

  return node;
};

RouteLoader.defaultProps = {
  auth: true,
};

export default RouteLoader;
