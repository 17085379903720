import React, { useEffect, useState } from 'react';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MobileStepper from '@mui/material/MobileStepper';

interface StepperProps {
  maxSteps: number;
  onStep: (step: number) => void;
  children: React.ReactNode;
}

const Stepper = function Stepper(props: StepperProps) {
  const { maxSteps, onStep, children } = props;
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  useEffect(() => {
    onStep(activeStep);
  });

  return (
    <Box>
      {children}
      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            Siguiente
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
            Anterior
          </Button>
        }
      />
    </Box>
  );
};

export default Stepper;
