import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import React from 'react';

interface CenterMessageProps {
  message?: string;
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'caption' | 'button';
  children?: React.ReactNode;
  sxContent?: any;
}

const CenterMessage = function CenterMessage({ message, variant, children, sxContent }: CenterMessageProps) {
  return (
    <Card sx={{ height: '100%' }}>
      <CardContent
        sx={{
          height: '80%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          ...sxContent,
        }}
      >
        {message && (
          <Typography variant={variant || 'h4'} sx={{ textAlign: 'center', mt: 2 }}>
            {message}
          </Typography>
        )}
        {children}
      </CardContent>
    </Card>
  );
};

export default CenterMessage;
