import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import { useApp } from 'src/hooks';
import Footer from './components/Footer/Footer';
import Sidebar from './components/Sidebar/Sidebar';
import Topbar from './components/Topbar/Topbar';

const RootDesktop = styled('div')(({ theme }) => ({
  paddingTop: 46,
  height: '100%',
  [theme.breakpoints.up('sm')]: {
    paddingTop: 46,
  },
}));

const mainSx = {
  p: 2,
  height: '100%',
  width: '100%',
  display: 'inline-flex',
  flexDirection: 'column',
};

const Main = function Main(props) {
  const { children } = props;
  const theme = useTheme();
  const { checkPerms } = useApp();
  const [openSidebar, setOpenSidebar] = useState(false);
  const handleSidebarOpen = () => setOpenSidebar(!openSidebar);

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });

  const canSidebarShow = useMemo(() => checkPerms('sidebar.show'), [checkPerms]);
  const shouldOpenSidebar = (isDesktop || openSidebar) && canSidebarShow;
  const rootSx = shouldOpenSidebar ? { paddingLeft: 240 } : {};

  return (
    <RootDesktop style={rootSx}>
      <Topbar onSidebarOpen={handleSidebarOpen} showIconSidebar={canSidebarShow} />
      <Sidebar open={shouldOpenSidebar} variant={isDesktop ? 'persistent' : 'temporary'} />
      <Box component="main" sx={mainSx}>
        {children}
        <Footer />
      </Box>
    </RootDesktop>
  );
};

Main.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Main;
