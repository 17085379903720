import { createRoot } from 'react-dom/client';
import App from './app';
import './assets/scss/index.scss';

// import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container); // createRoot(container!) if you use TypeScript

  /**
   * React.StrictMode: https://reactjs.org/docs/strict-mode.html
   * Strict mode checks are run in development mode only; they do not impact the production build.
   *
   * Hace doble renderizado para detectar problemas.
   * https://stackoverflow.com/questions/61193122/react-default-create-react-app-app-component-rendered-twice
   */
  // root.render(
  //   <React.StrictMode>
  //     <App />
  //   </React.StrictMode>
  // );

  root.render(<App />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
