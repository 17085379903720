import esLocale from 'date-fns/locale/es';
import { ConfirmProvider } from 'material-ui-confirm';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AppProvider } from 'src/contexts/AppContext';
import { SoundProvider } from 'src/contexts/SoundContext';
import { ToastProvider } from 'src/contexts/ToastContext';
import xhr from 'src/helpers/xhr';
import { useApp } from 'src/hooks';
import Routes from 'src/routes';
import createThemeFromContext from 'src/theme';
import { errorHandler, errorReset } from './api/errors';
import { ErrorBoundary, ErrorFallback } from './components/errors';

const soundAtiscam = require('src/assets/sounds/sound-atiscam.mp3');

xhr.setApiBase(`${process.env.REACT_APP_BACKEND_LOCATION}/api`);

const spriteSound = {
  concedido: [0, 656],
  denegado: [699, 1800],
};

const Base = function Base() {
  const { context } = useApp();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createThemeFromContext(context)}>
        <CssBaseline />
        <BrowserRouter>
          <ToastProvider horizontal="center" autoHideDuration={5000}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
              <ConfirmProvider>
                <Routes />
              </ConfirmProvider>
            </LocalizationProvider>
          </ToastProvider>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const Root = function Root() {
  return (
    <AppProvider>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler} onReset={errorReset}>
        <SoundProvider file={soundAtiscam} sprite={spriteSound}>
          <Base />
        </SoundProvider>
      </ErrorBoundary>
    </AppProvider>
  );
};

Root.propTypes = {};

export default Root;
