import components from './components';
import createPaletteFromContext from './palette';
import typography from './typography';
import { Theme, createTheme } from '@mui/material';
import { esES } from '@mui/material/locale';
import { ContextApp } from 'src/api/context';

function createThemeFromContext(context: ContextApp): Theme {
  const theme = createTheme(
    {
      palette: createPaletteFromContext(context),
      typography,
      components,
      spacing: 4,
      zIndex: { appBar: 1200, drawer: 1100 },
    },
    esES
  );

  return theme;
}

export default createThemeFromContext;
