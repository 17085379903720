import { createContext, useCallback, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { ContextApp, DefaultContext, getContext, getPublicContext } from 'src/api/context';
import { LoginProps, ResponseLogin, login as apiLogin } from 'src/api/login';
import { logout as apiLogout } from 'src/api/logout';
import { currentUser } from 'src/api/users';
import { Loading } from 'src/components';
import { get } from 'src/helpers';
import { User } from 'src/types';

interface AppProviderProps {
  children: React.ReactNode;
}

interface ContextProps {
  user: User | null;
  context: ContextApp;
  startingApplication: boolean;
  isAuthenticated: boolean;
  checkPerms: (permissions: string | string[]) => boolean;
  checkPermsExp: (permissions: string | string[], expression: any) => any;
  login: (props: LoginProps) => Promise<ResponseLogin>;
  logout: () => Promise<void>;
  setUser: (user: User) => void;
}

export const AppContext = createContext<ContextProps>({
  user: null,
  context: DefaultContext,
  startingApplication: true,
  isAuthenticated: window.localStorage.getItem('isAuthenticated') === 'true',
  checkPerms: () => false,
  checkPermsExp: () => {},
  login: async () => ({ message: 'empty' }),
  logout: async () => {},
  setUser: () => {},
});

export const AppProvider = function AppProvider(props: AppProviderProps) {
  const { children } = props;
  const [contextApp, setContextApp] = useState<ContextApp>(DefaultContext);
  const [user, setUser] = useState<User | null>(null);
  const [startingApplication, setStartingApplication] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(window.localStorage.getItem('isAuthenticated') === 'true');

  const login = useCallback(async function login({ email, password }: LoginProps) {
    const response = await apiLogin({ email, password });
    window.localStorage.setItem('isAuthenticated', 'true');
    setIsAuthenticated(true);
    return response;
  }, []);

  const logout = useCallback(async function logout() {
    try {
      return await apiLogout();
    } finally {
      localStorage.setItem('isAuthenticated', 'false');
      setIsAuthenticated(false);
    }
  }, []);

  const checkPerms = useCallback(
    (permissions: string | string[]) => {
      const perms = get(user, 'perms', []);
      if (typeof permissions === 'string') {
        if (perms.includes(permissions)) {
          return true;
        }
      }
      for (let index = 0; index < permissions.length; index++) {
        const permission = permissions[index];
        if (perms.includes(permission)) {
          return true;
        }
      }
      return false;
    },
    [user]
  );

  const checkPermsExp = useCallback(
    (permissions: string | string[], expression: any) => {
      if (checkPerms(permissions)) {
        return expression;
      }
    },
    [checkPerms]
  );

  const context = {
    user,
    context: contextApp,
    startingApplication,
    isAuthenticated,
    checkPerms,
    checkPermsExp,
    login,
    logout,
    setUser,
  };

  useEffectOnce(() => {
    const initialize = async () => {
      let publicContext: ContextApp | undefined;

      // get public context
      publicContext = await getPublicContext();
      document.title = publicContext.name;
      process.env.NODE_ENV === 'development' && console.debug(`[INITIALIZE] public context:`, publicContext.name);

      try {
        process.env.NODE_ENV === 'development' && console.debug(`[INITIALIZE] auth: ${isAuthenticated}`);

        // si no está autenticado => fin
        if (!isAuthenticated) {
          setContextApp(publicContext);
          return;
        }

        // get private context
        const privateContext = await getContext();
        const finalContext = { ...publicContext, ...privateContext };
        setContextApp(finalContext);
        process.env.NODE_ENV === 'development' && console.debug(`[INITIALIZE] private context:`, finalContext.name);

        // get current user
        const user = await currentUser();
        setUser(user);
        process.env.NODE_ENV === 'development' && console.debug(`[INITIALIZE] user:`, user.email);
      } catch (err) {
        localStorage.setItem('isAuthenticated', 'false');
        setIsAuthenticated(false);
      } finally {
        setTimeout(() => setStartingApplication(false), 100);
      }
    };

    initialize();
  });

  if (startingApplication) {
    return <Loading status="Inicializando aplicación..." expanded size={50} />;
  }

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};
