import React, { useState } from 'react';
import Alert, { AlertColor } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';

export const ToastContext = React.createContext({
  isOpen: false,
  info: (message: string) => {},
  success: (message: string) => {},
  error: (message: string) => {},
  warning: (message: string) => {},
});

interface SnackStateType {
  open: boolean;
  severity: AlertColor;
  message: string;
}

interface ToastProviderProps {
  children: React.ReactNode;
  vertical: 'top' | 'bottom';
  horizontal: 'left' | 'center' | 'right';
  autoHideDuration?: number;
}

export const ToastProvider = function ToastProvider(props: ToastProviderProps) {
  const { autoHideDuration, children, vertical, horizontal } = props;
  const [snack, setSnack] = useState<SnackStateType>({ open: false, severity: 'success', message: '' });
  const handleClose = () => setSnack({ ...snack, open: false });
  const showToast = (severity: AlertColor, message: string) => setSnack({ open: true, severity, message });

  const data = React.useMemo(
    () => ({
      isOpen: snack.open,
      info: (message: string) => showToast('info', message),
      success: (message: string) => showToast('success', message),
      error: (message: string) => showToast('error', message),
      warning: (message: string) => showToast('warning', message),
    }),
    [snack.open]
  );

  return (
    <ToastContext.Provider value={data}>
      <Snackbar
        open={snack.open}
        onClose={handleClose}
        autoHideDuration={autoHideDuration}
        anchorOrigin={{ vertical, horizontal }}
        data-testid="toast-alert"
      >
        <Alert elevation={6} variant="filled" onClose={handleClose} severity={snack.severity} sx={{ width: '100%' }}>
          <Typography style={{ whiteSpace: 'pre-line' }}>{snack.message}</Typography>
        </Alert>
      </Snackbar>
      {children}
    </ToastContext.Provider>
  );
};

ToastProvider.defaultProps = {
  autoHideDuration: null,
  vertical: 'top',
  horizontal: 'right',
};
