import { useSound as useSoundLibrary } from 'use-sound';
import { PlayOptions } from 'use-sound/dist/types';
import { createContext, useCallback, useMemo, useState } from 'react';

interface SoundContextType {
  isEnable: boolean;
  play: (id: PlayOptions | undefined) => void;
  setEnable: (enable: boolean) => void;
}

export const SoundContext = createContext<SoundContextType>({
  isEnable: true,
  play: (id: PlayOptions | undefined) => {},
  setEnable: (value: boolean) => {},
});

interface SoundProviderProps {
  file: string;
  sprite: any;
  children: React.ReactNode;
}

export const SoundProvider = function SoundProvider(props: SoundProviderProps) {
  const { file, sprite, children } = props;
  const [play] = useSoundLibrary(file, { sprite });
  const [enable, setEnable] = useState(true);
  const playSound = useCallback((id: PlayOptions | undefined) => (sprite ? play(id) : play()), [play, sprite]);

  const data = useMemo(
    () => ({
      isEnable: enable,
      play: playSound,
      setEnable,
    }),
    [enable, playSound]
  );

  return <SoundContext.Provider value={data}>{children}</SoundContext.Provider>;
};

SoundProvider.defaultProps = {
  sprite: null,
};
