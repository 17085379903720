import React from 'react';
import { Link, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useApp } from 'src/hooks';

const Footer = function Footer() {
  const { context } = useApp();
  const { versions } = context;

  return (
    <Box
      sx={{
        marginTop: 2,
        paddingBottom: 0,
        flexGrow: 1,
        alignSelf: 'self-end',
        justifyContent: 'flex-end',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ textAlign: 'right' }}>
        <Link component="a" href="https://atisoluciones.com/" target="_blank" underline="hover">
          ATISoluciones 2024
        </Link>
        {' - '}
        <Tooltip
          placement="top-start"
          data-testid="version-number-tooltip"
          title={
            <>
              <Typography>{`Versión back: ${versions.back}`}</Typography>
              <Typography>{`Versión front: ${versions.front}`}</Typography>
              <Typography>{`Versión node: ${versions.node}`}</Typography>
              <Typography>{`Versión rtsp: ${versions.rtsp}`}</Typography>
            </>
          }
        >
          <span data-testid="version-number">{` ${context.context} ${versions.back}`}</span>
        </Tooltip>
      </Box>

      <Typography variant="caption" component="p">
        Especialistas en diseño de hardware y software, seguridad Integral e I+D+i.
      </Typography>
    </Box>
  );
};

export default React.memo(Footer);
