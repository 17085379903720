import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApp } from 'src/hooks';

interface AuthGuardProps {
  children: JSX.Element;
}

const AuthGuard = function AuthGuard(props: AuthGuardProps) {
  const navigate = useNavigate();
  const { children } = props;
  const { startingApplication, isAuthenticated } = useApp();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  if (startingApplication) {
    return null;
  }

  return children;
};

export default AuthGuard;
