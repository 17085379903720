import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

interface LoadingButtonProps extends ButtonProps {
  children: React.ReactNode;
  loading: boolean;
  onClick?: () => void;
  loadingText?: string;
  startIcon?: React.ReactNode;
}

const LoadingButton = function LoadingButton(props: LoadingButtonProps) {
  const { children, onClick, loading, loadingText, startIcon, disabled, ...rest } = props;

  return (
    <Button {...rest} onClick={onClick} disabled={loading || disabled} startIcon={loading ? <CircularProgress size={12} /> : startIcon}>
      {loading && loadingText && <Typography>{loadingText}</Typography>}
      {loading && !loadingText && children}
      {!loading && children}
    </Button>
  );
};

LoadingButton.defaultProps = {
  loadingText: '',
  startIcon: null,
};

export default LoadingButton;
