import Item from './Item';
import Root from './Root';

const Sidebar = function Sidebar() {
  return (
    <Root>
      <Item title="Panel de control" href="/dashboard" icon="fa-parking" permissions="dashboard.show" testId="dashboard" />

      {/* ADMINISTRACION */}
      <Item
        title="Administración"
        href="/admin"
        icon="fa-user"
        testId="administracion"
        permissions={['usuarios.list', 'operadores.list', 'clientes.list', 'centros.list', 'consultas.adminstracion']}
      >
        <Item subItem title="Usuarios" href="/admin/usuarios" permissions="usuarios.list" testId="administracion-usuarios" />
        <Item subItem title="Operadores" href="/admin/operadores" permissions="operadores.list" testId="administracion-operadores" />
        <Item subItem title="Clientes" href="/admin/clientes" permissions="clientes.list" testId="administracion-clientes" />
        <Item subItem title="Centros / Entidades" href="/admin/entidades" permissions="centros.list" testId="administracion-centros" />
        <Item subItem title="Consultas" href="/admin/consultas" permissions="consultas.adminstracion" testId="administracion-consultas" />
      </Item>

      {/* APARCAMIENTOS */}
      <Item
        title="Aparcamientos"
        href="/aparcamientos"
        icon="fa-car"
        testId="aparcamientos"
        permissions={['aparcamientos.list', 'aparcamientos-cupos.list', 'aparcamientos-transitos.list']}
      >
        <Item subItem title="Aparcamientos" href="/aparcamientos/list" permissions="aparcamientos.list" testId="aparcamientos-list" />
        <Item subItem title="Cupos" href="/aparcamientos/cupos/list" permissions="aparcamientos-cupos.list" testId="aparcamientos-cupos" />
        <Item subItem title="Tránsitos" href="/aparcamientos/transitos" permissions="aparcamientos-transitos.list" testId="aparcamientos-transitos" />
      </Item>

      {/* EDIFICIOS */}
      <Item
        title="Edificios"
        href="/edificios"
        icon="fa-building"
        testId="edificios"
        permissions={['edificios.list', 'edificios-cupos.list', 'edificios-transitos.list']}
      >
        <Item subItem title="Edificios" href="/edificios/list" permissions="edificios.list" testId="edificios-list" />
        <Item subItem title="Cupos" href="/edificios/cupos/list" permissions="edificios-cupos.list" testId="edificios-cupos" />
        <Item subItem title="Tránsitos" href="/edificios/transitos" permissions="edificios-transitos.list" testId="edificios-transito" />
      </Item>

      {/* ACCESOS */}
      <Item title="Accesos" href="/accesos" icon="fa-road-barrier" permissions={['accesos.list', 'imports.show']} testId="accesos">
        <Item subItem title="Accesos" href="/accesos" permissions="accesos.list" testId="accesos-list" />
        <Item subItem title="Importaciones" href="/accesos/imports" permissions="imports.show" testId="accesos-list" />
        <Item subItem title="Vehículos inactivos" href="/accesos/vehiculos-inactivos" permissions="vehiculos-inactivos.show" testId="accesos-list" />
      </Item>

      {/* CONFIGURACION */}
      <Item
        title="Configuración"
        href="/config"
        icon="fa-cog"
        testId="configuracion"
        permissions={['tipos-matriculas.list', 'perfiles-horarios.list', 'consola.show', 'ftp.show', 'roles.list', 'auditoria.list']}
      >
        <Item subItem title="Auditoría" href="/config/auditoria" permissions="auditoria.list" testId="configuracion-auditoria" />
        <Item subItem title="Backups" href="/config/backups" permissions="backups.list" testId="configuracion-backups" />
        <Item subItem title="Consola" href="/config/consola" permissions="consola.show" testId="configuracion-consola" />
        <Item
          subItem
          title="Escritorio"
          href="/desktop/vnc.html?resize=remote&path=desktop/websockify"
          permissions="consola.show"
          blank
          testId="configuracion-escritorio"
        />
        <Item subItem title="Errores" href="/config/errors" permissions="frontend-error.list" testId="configuracion-errores" />
        <Item subItem title="Ftp ocr" href="/config/ftp" permissions="ftp.show" testId="configuracion-ftp" />
        <Item
          subItem
          title="Perfiles horarios"
          href="/config/perfiles-horarios"
          permissions="perfiles-horarios.list"
          testId="configuracion-perfiles-horarios"
        />
        <Item
          subItem
          title="Perfiles de acceso"
          href="/config/perfiles-acceso"
          permissions="perfiles-acceso.list"
          testId="configuracion-perfiles-acceso"
        />
        <Item subItem title="Roles" href="/config/roles" permissions="roles.list" testId="configuracion-roles" />
        <Item subItem title="Tareas" href="/config/tareas" permissions="tareas-programadas.list" testId="configuracion-tareas" />
        <Item
          subItem
          title="Tipos de matrículas"
          href="/config/tipos-matriculas"
          permissions="tipos-matriculas.list"
          testId="configuracion-tipos-matriculas"
        />
        <Item subItem title="Variables" href="/config/variables/sistema" permissions="variables.list" testId="configuracion-variables" />
      </Item>
    </Root>
  );
};

Sidebar.propTypes = {};

export default Sidebar;
