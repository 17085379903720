import xhr, { REDIRECT_502, request } from 'src/helpers/xhr';
import VERSION from 'src/version';

interface ContextPublicApp {
  context: string;
  name: string;
  theme_palette_primary: string;
  logo: string;
  logo_alt: string;
  logo_header: string;
  logo_header_alt: string;
  extra_login?: string | null;
  versions: {
    back: string;
    front: string;
    node: string;
    rtsp: string;
  };
}

export interface ContextApp extends ContextPublicApp {
  pusher_app_key: string;
  pusher_force_tls: boolean;
  pusher_app_cluster: string;
  pusher_disable_stats: boolean;
  pusher_enabled_transports: string;
}

export const DefaultContext: ContextApp = {
  context: '',
  name: '...',
  theme_palette_primary: '#004D87',
  logo: '',
  logo_alt: '',
  logo_header: '',
  logo_header_alt: '',
  pusher_app_key: '',
  pusher_force_tls: false,
  pusher_app_cluster: '',
  pusher_disable_stats: true,
  pusher_enabled_transports: 'ws',
  versions: {
    front: '-',
    back: '-',
    node: '-',
    rtsp: '-',
  },
};

const IMG_MAINTENANCE =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAMAAABHPGVmAAAAb1BMVEUAAAAYMVNSZX5CV3NIXHdldoxhcolabYV9i594hppXaYJMX3qJlqc3TGoySGYrQmEiOltqepFygZYeN1gcNVaXorKQnKyMmKk8Um7Z3eOfqbdeb4evuMOlr7yGk6UmPl7J0NeTn69/jaC1vci7wsw92qSRAAAAAXRSTlMAQObYZgAAAh5JREFUaN7t2dtu4jAQgOGZnElCDiScApRD9/2fcbV7M7vYkR3PTFRV/S9RxSfjTAwNcDtkSTMM99OlBpX6Et/qhYV9gpZOksShQ3tyxKPBmQYxIx9xrlzK2OJskRDx2c0bpZDxRH2jxvkuQsYN58uEjGkFA5oVjJ3+fsAH6s8gtGrXLnXSm3NqdtITOSNhGOzbYixoTIHG+bEA6e1G6x6tiDfs7g3Jln27QHuVy6DVuktDRiRd+OUiCkAyVEfIwE3gvruRC/1R7YkMS5EcqdQTwYVIaVyC7s4LkSjkzvNYhlRoIOIrSQLPzSVIjG/tmFdX4mFgzT9N3Kd0wZz4MXUbCJ6VOFdfAbVvkYG8MLy7L3JmIAX4FoUj4F3CQfRXkn6pT4thNCsYuIbRsqfd3ahhBN7lc45xX8HofukbY6pvYKm/51iozwcOkb7R+q2j4hjNWd3onuqHFEagbmRA8T+rre21K4gaGcCr2P4LFACi5zkd3RPEQEkblJbR84zqqxg7npF8EyP2MTY/xp9O32QdP4awUfCMzMd48YwrUtvk0liND+BF73T/e0IfR9Oomcbe+H33aRhHuadIKb0kbIDlh2rxv3EADeQmvA440LtN9J98wxDb99j2jKyWfmac0uJoPkQyRq4XXAchZmToI0eQaoOUOR/aS3mCYLnd2INonaJBjaYxgXjDu3EDSukZdQc6XVsyCtCrzpuxizWE370gHGahUh/UAAAAAElFTkSuQmCC';

const PATH_MAINTENANCE = '/maintenance';

export async function getPublicContext(): Promise<ContextApp> {
  const path = window.location.pathname;
  const url = `${process.env.REACT_APP_BACKEND_LOCATION}/public-context?r=${Math.random()}`;

  try {
    process.env.NODE_ENV === 'development' && console.debug(`[PUBLIC CONTEXT] fetch public context [${path}]`);
    const response = await request('get', url, {}, 'json', null, !path.includes(PATH_MAINTENANCE));

    if (response && response.status === 200) {
      const publicContext = { ...DefaultContext, ...response.data } as ContextApp;
      publicContext.versions.front = VERSION as string;
      return publicContext;
    } else {
      console.error(`[PUBLIC CONTEXT][${response?.status}] no se ha podido leer el contexto`, response);
      return { ...DefaultContext, logo: IMG_MAINTENANCE };
    }
  } catch (err: any) {
    console.error(`[PUBLIC CONTEXT]: exception`, err.message);
    localStorage.setItem('isAuthenticated', 'false');

    if (!path.includes(PATH_MAINTENANCE)) {
      window.location.replace(REDIRECT_502);
    }
    return { ...DefaultContext, logo: IMG_MAINTENANCE };
  }
}

export async function getContext(): Promise<ContextApp> {
  const response = await xhr.get(`${process.env.REACT_APP_BACKEND_LOCATION}/api/context?r=${Math.random()}`);
  return response;
}
