import { PaletteOptions, colors } from '@mui/material';
import { ContextApp } from 'src/api/context';

function createPaletteFromContext(context: ContextApp) {
  const white = '#FFFFFF';
  // const black = '#000000';

  const palette: PaletteOptions = {
    primary: {
      contrastText: white,
      dark: context.theme_palette_primary,
      main: context.theme_palette_primary,
      light: context.theme_palette_primary,
    },
    secondary: {
      contrastText: white,
      dark: '#762531',
      main: '#A93646',
      light: '#ba5e6b',
    },
    success: {
      contrastText: white,
      dark: colors.green[900],
      main: colors.green[600],
      light: colors.green[400],
    },
    info: {
      contrastText: white,
      dark: colors.blue[900],
      main: colors.blue[600],
      light: colors.blue[400],
    },
    warning: {
      contrastText: white,
      dark: colors.orange[900],
      main: colors.orange[600],
      light: colors.orange[400],
    },
    error: {
      contrastText: white,
      dark: colors.red[900],
      main: colors.red[600],
      light: colors.red[400],
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
      // link: colors.blue[600],
    },
    background: {
      default: '#F4F6F8',
      paper: white,
    },
    // icon: colors.blueGrey[600],
    divider: colors.grey[200],
  };

  return palette;
}

export default createPaletteFromContext;
