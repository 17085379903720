import { Box, Link } from '@mui/material';
import { CenterMessage } from 'src/components';
import Logo from '../Logo';

export default function NotPerms() {
  return (
    <CenterMessage sxContent={{ flexDirection: 'column' }}>
      <Box sx={{ paddingBottom: 5 }}>
        <Logo imageName="logo" width={100} linkTo="/" />
      </Box>
      <Link href="/" variant="h4">
        No tienes permisos para estar aquí
      </Link>
    </CenterMessage>
  );
}
