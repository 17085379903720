import { get } from 'lodash';
import { AjaxError } from 'src/api/errors';

export default function errorToToast(err) {
  if (err instanceof AjaxError) {
    return get(err, 'response.data.message', err.message);
  }

  if (err instanceof Error) {
    return err.message;
  }

  if (typeof err === 'object') {
    let messages = Object.values(err);
    return messages.join('\n');
  }

  return err;
}
