import xhr from 'src/helpers/xhr';

export interface LoginProps {
  email: string;
  password: string;
  remember?: boolean;
}

export interface ResponseLogin {
  message: string;
  errors?: { [key: string]: string[] };
}

export async function getCsrf() {
  return xhr.get(`${process.env.REACT_APP_BACKEND_LOCATION}/sanctum/csrf-cookie`);
}

export async function login(props: LoginProps) {
  await getCsrf();
  const response = await xhr.post(`${process.env.REACT_APP_BACKEND_LOCATION}/login`, props);
  return response as ResponseLogin;
}
