import React from 'react';
import { useNavigate } from 'react-router-dom';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Hidden, IconButton, Menu, MenuItem, Toolbar } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { DialogConfirmation } from 'src/components';
import Logo from 'src/components/Logo';
import { useApp } from 'src/hooks';
import Alerts from './Alerts';

interface TopbarProps {
  onSidebarOpen?: () => void;
  showIconSidebar: boolean;
}

const Topbar = function Topbar(props: TopbarProps) {
  const { onSidebarOpen, showIconSidebar } = props;
  const navigate = useNavigate();
  const { logout } = useApp();
  const [openLogout, setOpenLogout] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handeOpenLogout = () => setOpenLogout(true);
  const handeLogout = async () => {
    await logout();
    navigate('/');
  };
  const handleProfileMenuOpen = (event: any) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const handleMyAccont = () => {
    handleMenuClose();
    navigate('/mi-cuenta');
  };
  const handleDialogLogoutClose = (ok: boolean) => (ok ? handeLogout() : setOpenLogout(false));
  const isMenuOpen = Boolean(anchorEl);
  const menuId = 'primary-account-menu';

  return (
    <AppBar position="fixed" sx={{ boxShadow: 'none', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <Box style={{ height: '46px' }}>
          <Logo linkTo="/dashboard" imageName="logo_header" width="auto" />
        </Box>

        <Box sx={{ flexGrow: 1 }} />

        <Alerts />

        <IconButton sx={{ ml: 1 }} color="inherit" onClick={handleProfileMenuOpen} size="large" data-testid="my-account-btn">
          <AccountCircle />
        </IconButton>

        {showIconSidebar && (
          <Hidden lgUp>
            <IconButton color="inherit" onClick={onSidebarOpen}>
              <MenuIcon />
            </IconButton>
          </Hidden>
        )}
      </Toolbar>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={menuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMyAccont} data-testid="my-account-item-account">
          <ListItemIcon>
            <ManageAccountsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Mi perfil</ListItemText>
        </MenuItem>
        <MenuItem onClick={handeOpenLogout} data-testid="my-account-item-logout">
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Desconectar</ListItemText>
        </MenuItem>
      </Menu>

      <DialogConfirmation open={openLogout} title="Desconectar" message="¿Estás seguro de cerrar sessión?" onClose={handleDialogLogoutClose} />
    </AppBar>
  );
};

Topbar.propTypes = {};

export default Topbar;
