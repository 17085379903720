import { Link as RouterLink } from 'react-router-dom';
import { Avatar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import get from 'src/helpers/get';
import { useApp } from 'src/hooks';

const StyledDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: 'fit-content',
  marginTop: 50,
}));

const StyledAvatar = styled(Avatar)(() => ({
  width: 60,
  height: 60,
}));

const TypographyName = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

const Profile = function Profile() {
  const { user } = useApp();
  const name = get(user, 'name', '<Sin nombre>');
  const bio = get(user, 'roles.0', null);
  const avatar = user ? `${process.env.REACT_APP_BACKEND_LOCATION}/files/fotos/${user.id}?type=user&r=${Math.random()}` : '';

  return (
    <StyledDiv>
      <StyledAvatar alt="Person" component={RouterLink} src={avatar} to="/dashboard" />
      <TypographyName variant="h6" dusk="user-name">
        {name}
      </TypographyName>
      <Typography variant="body2" dusk="user-bio">
        {bio}
      </Typography>
    </StyledDiv>
  );
};

export default Profile;
