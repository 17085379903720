import isString from 'lodash/isString';
import { useEffect } from 'react';
import { AsyncState } from 'react-use/lib/useAsync';
import { Alert, Box } from '@mui/material';
import { Loading } from 'src/components';
import { get } from 'src/helpers';
import { useToast } from 'src/hooks';

interface RequestStatusProps {
  request: AsyncState<any>;
  mode: 'toast' | 'alert';
  showLoading: boolean;
  loadingText?: string;
}

const RequestStatus = function RequestStatus(props: RequestStatusProps) {
  const toast = useToast();
  const { request, showLoading, loadingText, mode } = props;
  const { value, error, loading } = request;

  const message = get(value, 'message');
  const errorMessage = isString(error) ? error : 'Revise los errores';

  // mostramos ventana flotante
  useEffect(() => {
    if (mode !== 'toast') {
      return;
    }

    if (loading && showLoading) {
      toast.info(loadingText || 'Cargando...');
      return;
    }

    if (!loading && error) {
      toast.error(errorMessage);
      return;
    }

    if (!loading && isString(message)) {
      toast.success(message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, errorMessage, message, error, loading, loadingText, showLoading]);

  // mostramos alertas html
  if (mode === 'alert') {
    if (loading && showLoading) {
      return (
        <Box sx={{ padding: 0, heigth: 20 }}>
          <Loading status={loadingText || 'Cargando...'} />
        </Box>
      );
    }

    if (error) {
      return (
        <Box sx={{ padding: 0, heigth: 20 }}>
          <Alert elevation={0} variant="filled" severity="error">
            {errorMessage}
          </Alert>
        </Box>
      );
    }
  }

  return null;
};

RequestStatus.defaultProps = {
  mode: 'toast',
  showLoading: false,
};

export default RequestStatus;
