import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const Root = styled('div')(() => ({
  height: '100%',
}));

const Main = styled('main')(() => ({
  height: '100%',
}));

interface LoadingProps {
  status?: string;
  expanded?: boolean;
  size?: number;
}

const Loading = function Loading(props: LoadingProps) {
  const { status, expanded, size } = props;
  const sizeValue = size || 25;

  if (!expanded) {
    return (
      <span>
        <CircularProgress size={sizeValue} />
        {status && (
          <Typography align="center" display="inline" sx={{ marginLeft: 10 }}>
            {status}
          </Typography>
        )}
      </span>
    );
  }

  return (
    <Root>
      <Main>
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: `${sizeValue}vh` }}>
          <CircularProgress size={sizeValue} sx={{ marginBottom: '20px' }} />
          {status && <Typography align="center">{status}</Typography>}
        </Grid>
      </Main>
    </Root>
  );
};

export default Loading;
