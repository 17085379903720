import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { HeaderPage } from 'src/components';

interface FormProps {
  children: React.ReactNode;
  showHeader: boolean;
  formTitle: string;
  formSubtitle: string;
  headerTitle?: string;
  headerTitleMaestro?: string;
  headerNow?: string;
}

const Form = function Form(props: FormProps) {
  const { showHeader, headerTitle, headerTitleMaestro, headerNow, formTitle, formSubtitle, children } = props;
  const navigate = useNavigate();
  const onBack = () => navigate(-1);

  if (showHeader) {
    return (
      <Box>
        <HeaderPage
          title={headerTitle || 'HeaderPageTitle'}
          l1={{ onClick: onBack, title: headerTitleMaestro || 'HeaderTitleMaestro' }}
          now={headerNow || 'HeaderNow'}
        />
        <Card>
          <CardHeader subheader={formSubtitle} title={formTitle} data-testid="card-header-form" />
          {children}
        </Card>
      </Box>
    );
  }

  return (
    <Card>
      <CardHeader subheader={formSubtitle} title={formTitle} />
      {children}
    </Card>
  );
};

export default Form;
