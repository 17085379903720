import { Backdrop, CircularProgress, Typography } from '@mui/material';

interface LoadingBackdropProps {
  text?: string;
}

export default function LoadingBackdrop(props: LoadingBackdropProps) {
  const { text } = props;

  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={!!text}>
      <CircularProgress color="inherit" />
      <Typography variant="h6" sx={{ color: '#fff', ml: 2 }}>
        {text}
      </Typography>
    </Backdrop>
  );
}
