import { ForwardedRef, forwardRef, useCallback, useImperativeHandle, useMemo } from 'react';
import { useAsyncFn, useEffectOnce } from 'react-use';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import TreeItem from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import { RequestStatus } from 'src/components';
import findNodeById from 'src/helpers/findNodeById';
import get from 'src/helpers/get';
import xhr from 'src/helpers/xhr';
import { SxProps } from '@mui/material';

interface Node {
  nodeId: string;
  name: string;
  type: string;
  open: boolean;
  children?: Node[];
  defaultExpanded?: boolean;
}

interface RemoteTreeProps {
  remote: string;
  renderNode: (node: any) => React.ReactNode;
  renderNodeStyle?: (node: any) => SxProps;
  onNodeSelect?: (node: any) => void;
}

export type RemoteTreeRef = {
  refresh: () => void;
};

const RemoteTree = forwardRef((props: RemoteTreeProps, ref: ForwardedRef<RemoteTreeRef>) => {
  const { remote, renderNode, renderNodeStyle, onNodeSelect } = props;
  const [request, refreshTreeData] = useAsyncFn<any>(() => xhr.get(remote), [remote]);
  const nodes = get(request, 'value', []);

  const renderTreeItem = useCallback(
    (nodesTree: any) =>
      nodesTree.map((node: Node) => {
        const sx = renderNodeStyle?.(node);

        return (
          <TreeItem key={node.nodeId} nodeId={node.nodeId} label={renderNode(node)} sx={sx} data-testid="remote-tree-item">
            {node.children ? renderTreeItem(node.children) : null}
          </TreeItem>
        );
      }),
    [renderNode, renderNodeStyle]
  );

  const defaultExpanded = useMemo(
    () =>
      nodes.map((node: Node) => {
        if (node.defaultExpanded) {
          return node.nodeId;
        }
        return null;
      }),
    [nodes]
  );

  const onNodeSelectParse = useCallback(
    (event: any, nodeIds: any) => {
      if (onNodeSelect) {
        const node = findNodeById(nodes, nodeIds);
        onNodeSelect(node);
      }
    },
    [nodes, onNodeSelect]
  );

  // exponemos el método refresh
  useImperativeHandle(ref, () => ({
    refresh() {
      refreshTreeData();
    },
  }));

  useEffectOnce(() => {
    refreshTreeData();
  });

  if (request.loading || request.error) {
    return <RequestStatus request={request} />;
  }

  return (
    <TreeView
      sx={{ flexGrow: 1 }}
      defaultExpanded={defaultExpanded}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
      onNodeSelect={onNodeSelectParse}
    >
      {renderTreeItem(nodes)}
    </TreeView>
  );
});

export default RemoteTree;
