import clone from 'lodash/clone';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useEffectOnce } from 'react-use';
import DescriptionIcon from '@mui/icons-material/Description';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import TreeItem from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import findNodeById from 'src/helpers/findNodeById';
import get from 'src/helpers/get';
import xhr from 'src/helpers/xhr';

interface Node {
  nodeId: string;
  name: string;
  type: string;
  open: boolean;
  children?: Node[];
}

interface RemoteFileExplorerProps {
  remote: string;
  onClickFile?: (node: Node) => void;
}

const setChildrenInTree = (nodes: Node[], id: string, children: Node[]) => {
  for (let index = 0; index < nodes.length; index++) {
    const node = nodes[index];

    if (node.nodeId === id) {
      nodes[index].children = children;
      break;
    }

    if (node.children) {
      nodes[index].children = setChildrenInTree(node.children, id, children);
    }
  }

  return nodes;
};

const replaceNode = (nodes: Node[], id: string, nodeReplace: Node) => {
  for (let index = 0; index < nodes.length; index++) {
    const node = nodes[index];

    if (node.nodeId === id) {
      nodes[index] = nodeReplace;
      return nodes;
    }

    if (node.children) {
      nodes[index].children = replaceNode(node.children, id, nodeReplace);
    }
  }

  return nodes;
};

const getNodeExpanded = (nodes: Node[], expanded: string[] = []) => {
  for (let index = 0; index < nodes.length; index++) {
    const node = nodes[index];

    if (node.open) {
      expanded.push(node.nodeId);
    }

    if (node.children) {
      expanded = getNodeExpanded(node.children, expanded);
    }
  }

  return expanded;
};

const renderTreeItem = (nodesTree: Node[]) =>
  nodesTree.map((node: Node) => {
    let icon = <DescriptionIcon />;

    if (node.type === 'dir') {
      icon = node.open ? <FolderOpenIcon /> : <FolderIcon />;
    }

    return (
      <TreeItem key={node.nodeId} nodeId={node.nodeId} label={node.name} endIcon={icon} collapseIcon={icon} expandIcon={icon}>
        {node.children ? renderTreeItem(node.children) : null}
      </TreeItem>
    );
  });

const RemoteFileExplorer = forwardRef((props: RemoteFileExplorerProps, ref) => {
  const { remote, onClickFile } = props;
  const [nodes, setNodes] = useState<Node[]>([]);
  const [expanded, setExpanded] = useState<string[]>([]);

  const fetchFolder = async (node?: Node) => {
    const path = get(node, 'data.path', null);
    const nodesFetched = await xhr.get(remote, { path });

    if (node) {
      node.children = nodesFetched as Node[];
      node.open = true;
      setNodes(clone(setChildrenInTree(nodes, node.nodeId, nodesFetched)));
    } else {
      setNodes(nodesFetched);
    }
  };

  const onNodeSelect = (_: any, nodeId: string) => {
    const node = findNodeById(nodes, nodeId);

    if (node.type === 'dir') {
      if (!node.open && node.children === null) {
        fetchFolder(node);
      } else if (!node.open) {
        node.open = true;
        setNodes(clone(replaceNode(nodes, nodeId, node)));
      } else {
        node.open = false;
        setNodes(clone(replaceNode(nodes, nodeId, node)));
      }
    } else {
      onClickFile?.(node);
    }
  };

  useEffectOnce(() => {
    fetchFolder();
  });

  useEffect(() => {
    setExpanded(getNodeExpanded(nodes));
  }, [nodes]);

  // exponemos el método refresh
  useImperativeHandle(ref, () => ({
    refresh() {
      fetchFolder();
    },
  }));

  return (
    <TreeView onNodeSelect={onNodeSelect} expanded={expanded} multiSelect={false} sx={{ flexGrow: 1 }}>
      {renderTreeItem(nodes)}
    </TreeView>
  );
});

export default RemoteFileExplorer;
