import HomeIcon from '@mui/icons-material/Home';
import { Box, Breadcrumbs, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMemo } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

const StyledDiv = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  display: 'flex',
}));

interface LevelType {
  title: string;
  to?: string;
  onClick?: () => void;
}

interface HeaderPageProps {
  title: string;
  subTitle?: string;
  now?: string;
  l1?: LevelType;
  l2?: LevelType;
  l3?: LevelType;
  l4?: LevelType;
  l5?: LevelType;
}

const HeaderPage = function HeaderPage(props: HeaderPageProps) {
  const { title, subTitle, now, l1, l2, l3, l4, l5 } = props;

  const nowMemo = useMemo(() => {
    if (!now) {
      return null;
    }

    // levels del breadcrum
    const propsLevels = [l1, l2, l3, l4, l5];
    const levels: any[] = [];

    propsLevels.forEach((level, index) => {
      if (level) {
        const { onClick, title: titleLevel, to } = level;
        let levelNode = null;

        if (onClick) {
          const onClickLevel = (event: any) => {
            event.preventDefault();
            onClick?.();
          };

          levelNode = (
            <Link key={`level-link-click-${index}`} color="inherit" href="#" onClick={onClickLevel} underline="hover">
              {titleLevel}
            </Link>
          );
        } else if (to) {
          levelNode = (
            <Link key={`level-link-${index}`} color="inherit" to={to} component={RouterLink} underline="hover">
              {titleLevel}
            </Link>
          );
        }

        if (levelNode) {
          levels.push(levelNode);
        }
      }
    });

    return (
      <Breadcrumbs aria-label="breadcrumb" sx={{ display: 'flex', flexDirection: 'column', marginLeft: 'auto', marginBottom: 1 }}>
        <Link color="inherit" to="/" component={RouterLink} underline="hover">
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Inicio
        </Link>
        {levels}
        <Typography color="textPrimary">{now}</Typography>
      </Breadcrumbs>
    );
  }, [now, l1, l2, l3, l4, l5]);

  const titleMemo = useMemo(() => {
    return (
      <Typography data-testid="header-page-title" variant="h6">
        {title}
      </Typography>
    );
  }, [title]);

  const subTitleMemo = useMemo(() => {
    if (!subTitle) {
      return null;
    }

    return (
      <Typography data-testid="header-page-subtitle" variant="caption" sx={{ pt: 2.5, ml: 2 }}>
        {subTitle}
      </Typography>
    );
  }, [subTitle]);

  return (
    <StyledDiv>
      <Box sx={{ display: 'flex' }}>
        {titleMemo}
        {subTitleMemo}
      </Box>
      {nowMemo}
    </StyledDiv>
  );
};

export default HeaderPage;
