import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const Root = styled('div')(() => ({
  paddingTop: 0,
  height: '100%',
}));

const Main = styled('main')(() => ({
  height: '100%',
}));

const Escritorio = function Escritorio(props) {
  const { children } = props;

  return (
    <Root>
      <Main>{children}</Main>
    </Root>
  );
};

Escritorio.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Escritorio;
