import { Column, RTRef, TableProps, RemoteTable } from '@atis/remote-table';
import { Action, Query, QueryResult } from '@material-table/core';
import esLocale from 'date-fns/locale/es';
import { isFunction, isPlainObject } from 'lodash';
import { ForwardedRef, forwardRef, useCallback, useMemo, useState } from 'react';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useTheme } from '@mui/material/styles';
import { errorToToast, xhr } from 'src/helpers';
import { useMounted, useToast } from 'src/hooks';
import LoadingBackdrop from './loading-backdrop';

type ActionRowTable<RowData extends object> = {
  rowId?: string;
  perms?: string | string[];
  url: string;
  method: 'get' | 'post' | 'delete' | 'patch';
  onComplete?: (response: any) => Promise<void>;
  parseDataEditable?: (newData: RowData, oldData?: RowData) => any;
  parseDataCellEditable?: (newValue: any, oldValue: any, rowData: RowData, columnDef: Column<RowData>) => any;
  isCellEditable?: (rowData: RowData, columnDef: Column<RowData>) => boolean;
};

type ActionTable<RowData extends object> =
  | Action<RowData>
  | ((rowData: RowData) => Action<RowData>)
  | { action: (rowData: RowData) => Action<RowData>; position: string };

type ActionRemoteTableCreate = (event: any) => void;

type ActionRemoteTableEdit<RowData extends object> = (event: any, row: RowData | RowData[]) => void;

export interface ColumnWithTableData<RowData extends object> extends Column<RowData> {
  readonly tableData: {
    filterValue: string;
  };
}

interface RemoteTableAtiscamProps<RowData extends object> extends Omit<TableProps<RowData>, 'onFetchData'> {
  remote: string;
  options: any;
  actions?: ActionTable<RowData>[];
  rowStyle?: any;
  title?: string;
  editable?: any;
  hideExportMenu?: boolean;
  onRowAdd?: ActionRowTable<RowData>;
  onRowUpdate?: ActionRowTable<RowData>;
  onRowDelete?: ActionRowTable<RowData>;
  onCellEditApproved?: ActionRowTable<RowData>;
  actionCreate?: ActionRemoteTableCreate;
  actionEdit?: ActionRemoteTableEdit<RowData>;
  actionDelete?: ActionRemoteTableEdit<RowData>;
}

type RowStyleDatatable = React.CSSProperties | ((data: any, index: number, level: number) => React.CSSProperties);

const iconActionAdd = () => <AddBoxIcon />;

const iconActionEdit = () => <EditOutlinedIcon />;

const iconActionDelete = () => <DeleteOutlineIcon />;

function RemoteTableAtiscam<RowData extends object>(props: RemoteTableAtiscamProps<RowData>, ref: ForwardedRef<RTRef>) {
  const isMounted = useMounted();
  const theme = useTheme();
  const toast = useToast();
  const {
    columns,
    remote,
    options,
    actions,
    rowStyle,
    editable,
    onRowAdd,
    onRowUpdate,
    onRowDelete,
    onCellEditApproved,
    hideExportMenu,
    onRowClick,
    actionCreate,
    actionEdit,
    actionDelete,
    ...propsRest
  } = props;
  const { title } = propsRest;
  const [performingOperation, setPerformingOperation] = useState<string | undefined>(undefined);

  const handleRemote = useCallback(
    async (query: Query<RowData>): Promise<QueryResult<RowData>> => {
      const response = await xhr.get(remote, query);
      if (!isMounted()) {
        return { data: [], page: 1, totalCount: 0 };
      }
      const { data, page, totalCount } = response;
      return { data: data as RowData[], page: page as number, totalCount: totalCount as number };
    },
    [isMounted, remote]
  );

  const optionsDefault = useMemo(() => {
    let rowStyleCompute: RowStyleDatatable = { fontFamily: theme.typography.fontFamily, ...theme.typography.body2 };

    if (isFunction(rowStyle)) {
      rowStyleCompute = (row) => ({ ...rowStyleCompute, ...rowStyle(row) });
    } else if (isPlainObject(rowStyle)) {
      rowStyleCompute = { ...rowStyleCompute, ...rowStyle };
    }

    interface OptionsWithTableData {
      padding: string;
      draggable: boolean;
      actionsColumnIndex: number;
      debounceInterval: number;
      rowStyle: RowStyleDatatable;
      filterCellStyle: { padding: string };
      pageSize: number;
      pageSizeOptions: number[];
      idSynonym: string;
      exportMenu?: any;
    }

    const options: OptionsWithTableData = {
      padding: 'dense',
      draggable: false,
      actionsColumnIndex: -1,
      debounceInterval: 150,
      rowStyle: rowStyleCompute,
      filterCellStyle: { padding: '6px 16px' },
      pageSize: 20,
      pageSizeOptions: [20, 50, 100],
      idSynonym: 'id',
    };

    if (!hideExportMenu) {
      options.exportMenu = [
        {
          label: 'Exportar a Excel',
          exportFunc: async (
            columns: ColumnWithTableData<RowData>[],
            renderData: RowData[],
            tableData: {
              searchedData: RowData[];
              filteredData: RowData[];
              groupedData: RowData[];
            }
          ) => {
            const filters = [];

            for (let index = 0; index < columns.length; index++) {
              const columnData = columns[index];
              const { field, column, tableData } = columnData;

              if (tableData) {
                filters.push({
                  column: { field, column },
                  operator: '=',
                  value: tableData.filterValue,
                });
              }
            }

            const query = {
              exportExcel: true,
              exportFileName: (title ? title : 'export') + '.xlsx',
              pageSize: 0,
              page: 0,
              orderDirection: 'asc',
              columns: columns.map((columnData) => {
                const { field, column, title, type } = columnData;
                return { field, column, title, type };
              }),
              filters,
            };

            setPerformingOperation('Exportando a Excel...');

            try {
              await xhr.download(remote, query);
            } catch (err) {
              toast.error(errorToToast(err));
            } finally {
              if (isMounted()) {
                setPerformingOperation(undefined);
              }
            }
          },
        },
      ];
    }

    return options;
  }, [theme, rowStyle, remote, title, toast, hideExportMenu, isMounted]);

  const actionsDefault = useMemo(() => {
    const actions: ActionTable<RowData>[] = [];

    if (actionCreate) {
      actions.push({
        icon: iconActionAdd,
        isFreeAction: true,
        onClick: actionCreate,
        tooltip: 'Nuevo',
      });
    }

    if (actionEdit) {
      actions.push({
        icon: iconActionEdit,
        tooltip: 'Editar',
        onClick: actionEdit,
      });
    }

    if (actionDelete) {
      actions.push({
        icon: iconActionDelete,
        tooltip: 'Eliminar',
        onClick: actionDelete,
      });
    }

    return actions;
  }, [actionCreate, actionEdit, actionDelete]);

  const defaultProps = useMemo(
    () => ({
      options: { ...optionsDefault, ...options },
      actions: [...actionsDefault, ...(actions ?? [])],
      ...propsRest,
    }),
    [options, actions, optionsDefault, actionsDefault, propsRest]
  );

  const editableMemo = useMemo(() => {
    const editableProp = { ...editable };

    const parseEditableMethod = (method: ActionRowTable<RowData>) => {
      return async (newData: RowData, oldData?: RowData) => {
        const parseUrl = (url: string, id: number) => url.replace('{rowId}', id?.toString());

        try {
          const parsedUrl = parseUrl(method.url, (newData as any).id);
          const parsedData = method.parseDataEditable ? method.parseDataEditable(newData) : newData;
          const response = await xhr[method.method](parsedUrl, parsedData);
          if (method.onComplete) {
            await method.onComplete(response);
          }
        } catch (err) {
          toast.error(errorToToast(err));
          throw err;
        }
      };
    };

    if (onRowAdd) {
      editableProp.onRowAdd = parseEditableMethod(onRowAdd);
    }

    if (onRowDelete) {
      editableProp.onRowDelete = parseEditableMethod(onRowDelete);
    }

    if (onRowUpdate) {
      editableProp.onRowUpdate = parseEditableMethod(onRowUpdate);
    }

    return editableProp;
  }, [onRowAdd, onRowDelete, onRowUpdate, editable, toast]);

  const cellEditableMemo = useMemo(() => {
    const parseEditableMethod =
      (method: ActionRowTable<RowData>) => async (newValue: any, oldValue: any, rowData: RowData, columnDef: Column<RowData>) => {
        const parseUrl = (url: string, id: number) => url.replace('{rowId}', id.toString());
        try {
          const parsedUrl = parseUrl(method.url, (rowData as any).id);
          const parsedData = method.parseDataCellEditable
            ? method.parseDataCellEditable(newValue, oldValue, rowData as RowData, columnDef as unknown as Column<RowData>)
            : newValue;
          const response = await xhr[method.method](parsedUrl, parsedData);
          if (method.onComplete) {
            method.onComplete(response);
          }
        } catch (err) {
          toast.error(errorToToast(err));
          throw err;
        }
      };

    if (onCellEditApproved) {
      return {
        onCellEditApproved: parseEditableMethod(onCellEditApproved),
        isCellEditable: (rowData: RowData, columnDef: Column<RowData>) =>
          onCellEditApproved.isCellEditable ? onCellEditApproved.isCellEditable(rowData as RowData, columnDef as unknown as Column<RowData>) : true,
      };
    }
  }, [onCellEditApproved, toast]);

  return (
    <>
      <LoadingBackdrop text={performingOperation} />
      <RemoteTable
        {...defaultProps}
        ref={ref}
        columns={columns}
        editable={editableMemo}
        cellEditable={cellEditableMemo}
        onFetchData={handleRemote}
        onRowClick={onRowClick}
        localization={{
          body: {
            dateTimePickerLocalization: esLocale,
          },
        }}
      />
    </>
  );
}

export default forwardRef(RemoteTableAtiscam);
