import axios from 'axios';
import { saveAs } from 'file-saver';
import { find, includes } from 'lodash';
import keys from 'lodash/keys';
import mapValues from 'lodash/mapValues';
import startsWith from 'lodash/startsWith';
import trim from 'lodash/trim';
import { AjaxError, sendError } from 'src/api/errors';
import get from 'src/helpers/get';

axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

export const REDIRECT_401 = '/login';
export const REDIRECT_502 = '/maintenance';

export async function request(method, url, params, responseType = 'json', files = null, redirect_when_error = true, timeout = 0) {
  const dataForm = new FormData();

  const config = {
    url,
    method,
    params: method === 'get' ? params : {},
    data: method !== 'get' ? params : {},
    body: params || {},
    timeout: timeout,
    responseType,
    headers: {
      Accept: 'application/json',
    },
  };

  if (files) {
    Object.keys(files).forEach((name) => {
      dataForm.append(name, files[name]);
    });

    Object.keys(params).forEach((key) => {
      dataForm.append(key, params[key]);
    });

    config['Content-Type'] = 'multipart/form-data';
    config.data = dataForm;
  }

  try {
    return await axios.request(config);
  } catch (err) {
    const status = get(err, 'response.status', '-');

    switch (status) {
      case 401: // sin auth
        localStorage.setItem('isAuthenticated', 'false');
        if (redirect_when_error) {
          window.location.replace(REDIRECT_401);
        }
        return null;
      case 502:
        localStorage.setItem('isAuthenticated', 'false');
        if (redirect_when_error) {
          window.location.replace(REDIRECT_502);
        }
        return null;
      case 422: {
        // unprocessable entity, error de validación
        const errors = get(err, 'response.data.errors', {});
        const errorForms = mapValues(errors, (error) => {
          if (error.length > 1) {
            return error.join(' ');
          }
          return error[0];
        });

        if (keys(errorForms).length === 0) {
          throw get(err, 'response.data.message');
        }

        throw errorForms;
      }
      default:
        const dataError = {
          status,
          request: get(err, 'request'),
          response: get(err, 'response'),
          message: get(err, 'message'),
          config,
        };
        sendError(err, dataError);
        throw new AjaxError(dataError);
    }
  }
}

async function requestData(method, url, params, files = null, redirect_when_error = true) {
  const r = await request(method, url, params, 'json', files, redirect_when_error);
  return r?.data;
}

async function requestDownload(method, url, params) {
  const r = await request(method, url, params, 'blob');

  const disposition = get(r, 'headers.content-disposition');
  const split = disposition.split(';');

  let filename = find(split, (parte) => includes(parte, 'filename'));
  filename = filename ? filename.split('=')[1] : 'download.xlsx';
  filename = trim(trim(filename, '"'));

  saveAs(r.data, filename);
}

let apiBase = '';

const composeUrl = (url) => (startsWith(url, '/') ? url : `${apiBase}/${url}`);

const setApiBase = (base) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('[API BASE URL]', base);
  }
  apiBase = base;
};

const xhr = {
  setApiBase,
  composeUrl,
  get: (url, data = {}) => requestData('get', composeUrl(url), data),
  post: (url, data = {}) => requestData('post', composeUrl(url), data),
  patch: (url, data = {}) => requestData('patch', composeUrl(url), data),
  delete: (url, data = {}) => requestData('delete', composeUrl(url), data),
  download: (url, data = {}) => requestDownload('get', composeUrl(url), data),
  upload: (url, files, data = {}) => requestData('post', composeUrl(url), data, files),
};

export default xhr;
