import { useEffect, useState } from 'react';
import NotPerms from 'src/components/views/NotPerms';
import { useApp } from 'src/hooks';

interface PermsGuardProps {
  children: JSX.Element;
  permissions: string[] | string;
  redirect403?: boolean;
}

const PermsGuard = function PermsGuard(props: PermsGuardProps) {
  const { children, permissions, redirect403 } = props;
  const { user, startingApplication, checkPerms } = useApp();
  const [checked, setChecked] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    setChecked(checkPerms(permissions));
  }, [checkPerms, permissions]);

  if (startingApplication || !user || checked === undefined) {
    return null;
  }

  if (!checked) {
    if (redirect403) {
      return <NotPerms />;
    }
    return null;
  }

  return children;
};

PermsGuard.defaultProps = {
  redirect403: false,
};

export default PermsGuard;
