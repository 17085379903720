import * as sourceStackTrace from 'sourcemapped-stacktrace';

function postAjax(url: string, data: any, success?: (response: any) => void) {
  var http = new XMLHttpRequest();

  http.onreadystatechange = function () {
    if (http.readyState === 4 && http.status === 200) {
      success?.(http.response);
    }
  };
  http.open('POST', url, true);
  http.setRequestHeader('Content-Type', 'application/json');
  http.send(JSON.stringify(data));

  return http;
}

export function sendError(error: Error, extra: any) {
  const isAuthenticated = window.localStorage.getItem('isAuthenticated') === 'true';
  process.env.NODE_ENV === 'development' && console.debug(`[ERROR] send error. auth [${isAuthenticated}]`, error);

  if (isAuthenticated) {
    try {
      sourceStackTrace.mapStackTrace(error.stack, (stackTrace: string[]) => {
        const data = {
          error: {
            name: error.name,
            message: error.message,
            stack: stackTrace,
          },
          extra,
        };

        postAjax(`${process.env.REACT_APP_BACKEND_LOCATION}/api/frontend-error`, data);
      });
    } catch (err: any) {
      console.error('IMPOSIBLE ENVIAR ERROR: ', err);
    }
  }
}

export function errorHandler(error: Error, info: any) {
  sendError(error, info);
}

export function errorReset() {
  // reset the state of your app so the error doesn't happen again
  window.location.href = '/?refresh=' + Math.random();
}

interface AjaxErrorProps {
  status: any;
  request: any;
  response: any;
  message: string;
  config: any;
}

export class AjaxError extends Error {
  status: any;
  request: any;
  response: any;
  message: string;
  config: any;

  constructor({ status, request, response, message, config }: AjaxErrorProps) {
    super(message || 'AjaxError');

    this.name = this.constructor.name;
    this.stack = AjaxError.createStack(this) as string | undefined;
    this.message = message;

    this.status = status;
    this.request = request;
    this.response = response;
    this.config = config;
  }

  toString() {
    return this.getPrettyMessage();
  }

  getPrettyMessage() {
    return `${this.message} STATUS: ${this.status}`;
  }

  static createStack(error: any) {
    return typeof Error.captureStackTrace === 'function' ? Error.captureStackTrace(error, error.constructor) : new Error().stack;
  }
}
