import { Navigate, Route, Routes as RoutesReact } from 'react-router-dom';
import { RouteLoader } from 'src/components';
import EscritorioLayout from 'src/layouts/Escritorio/Escritorio';
import LoginLayout from 'src/layouts/Login/Login';

function Routes() {
  return (
    <RoutesReact>
      <Route path="/" element={<Navigate replace to="/dashboard" />} />

      <Route path="/mi-cuenta" element={<RouteLoader view="Account" />} />

      <Route path="/dashboard">
        <Route path=":idAparcamiento/:idPuerta/detail" element={<RouteLoader view="00_dashboard/DashboardPuerta" permissions="dashboard.show" />} />
        <Route path=":idAparcamiento/:idPuerta" element={<RouteLoader view="00_dashboard/Dashboard" permissions="dashboard.show" />} />
        <Route path=":idAparcamiento" element={<RouteLoader view="00_dashboard/Dashboard" permissions="dashboard.show" />} />
        <Route path="" element={<RouteLoader view="00_dashboard/Dashboard" permissions="dashboard.show" />} />
      </Route>

      <Route path="/admin">
        <Route path="usuarios">
          <Route path="new" element={<RouteLoader view="01_admin/Usuarios/UsuariosNew" permissions="usuarios.create" />} />
          <Route path=":id/:tab/:idChild" element={<RouteLoader view="01_admin/Usuarios/UsuariosDetail" permissions="usuarios.list" />} />
          <Route path=":id/:tab" element={<RouteLoader view="01_admin/Usuarios/UsuariosDetail" permissions="usuarios.list" />} />
          <Route path=":id" element={<RouteLoader view="01_admin/Usuarios/UsuariosDetail" permissions="usuarios.list" />} />
          <Route path="" element={<RouteLoader view="01_admin/Usuarios/Usuarios" permissions="usuarios.list" />} />
        </Route>

        <Route path="operadores">
          <Route path="new" element={<RouteLoader view="01_admin/Operadores/OperadoresEditCreate" permissions="operadores.create" />} />
          <Route path=":id/:tab/:idChild" element={<RouteLoader view="01_admin/Operadores/OperadoresDetail" permissions="operadores.list" />} />
          <Route path=":id/:tab" element={<RouteLoader view="01_admin/Operadores/OperadoresDetail" permissions="operadores.list" />} />
          <Route path=":id" element={<RouteLoader view="01_admin/Operadores/OperadoresDetail" permissions="operadores.list" />} />
          <Route path="" element={<RouteLoader view="01_admin/Operadores/Operadores" permissions="operadores.list" />} />
        </Route>

        <Route path="clientes">
          <Route path="new" element={<RouteLoader view="01_admin/Clientes/ClientesEditCreate" permissions="clientes.create" />} />
          <Route path=":id/:tab/:idChild" element={<RouteLoader view="01_admin/Clientes/ClientesDetail" permissions="clientes.list" />} />
          <Route path=":id/:tab" element={<RouteLoader view="01_admin/Clientes/ClientesDetail" permissions="clientes.list" />} />
          <Route path=":id" element={<RouteLoader view="01_admin/Clientes/ClientesDetail" permissions="clientes.list" />} />
          <Route path="" element={<RouteLoader view="01_admin/Clientes/Clientes" permissions="clientes.list" />} />
        </Route>

        <Route path="entidades">
          <Route path="new" element={<RouteLoader view="01_admin/Entidades/EntidadesEditCreate" permissions="centros.create" />} />
          <Route path=":id/:tab/:idChild" element={<RouteLoader view="01_admin/Entidades/EntidadesDetail" permissions="centros.list" />} />
          <Route path=":id/:tab" element={<RouteLoader view="01_admin/Entidades/EntidadesDetail" permissions="centros.list" />} />
          <Route path=":id" element={<RouteLoader view="01_admin/Entidades/EntidadesDetail" permissions="centros.list" />} />
          <Route path="" element={<RouteLoader view="01_admin/Entidades/Entidades" permissions="centros.list" />} />
        </Route>

        <Route path="consultas" element={<RouteLoader view="01_admin/Consultas/Consultas" permissions="consultas.adminstracion" />} />
      </Route>

      <Route path="/aparcamientos">
        <Route path="list" element={<RouteLoader view="02_aparcamientos/aparcamientos/list-parking" permissions="aparcamientos.list" />} />
        <Route path="new" element={<RouteLoader view="02_aparcamientos/aparcamientos/new-parking" permissions="aparcamientos.create" />} />
        <Route
          path="detail/:id/:tab"
          element={<RouteLoader view="02_aparcamientos/aparcamientos/detail-parking" permissions="aparcamientos.list" />}
        />
        <Route path="detail/:id" element={<RouteLoader view="02_aparcamientos/aparcamientos/detail-parking" permissions="aparcamientos.list" />} />
        <Route path="cupos/list" element={<RouteLoader view="02_aparcamientos/cupos/cupos-parking" permissions="aparcamientos-cupos.list" />} />
        <Route
          path="transitos"
          element={<RouteLoader view="02_aparcamientos/transitos/transitos-parking" permissions="aparcamientos-transitos.list" />}
        />
      </Route>

      <Route path="/edificios">
        <Route path="list" element={<RouteLoader view="03_edificios/edificios/list-building" permissions="edificios.list" />} />
        <Route path="new" element={<RouteLoader view="03_edificios/edificios/new-building" permissions="edificios.create" />} />
        <Route path="detail/:id/:tab" element={<RouteLoader view="03_edificios/edificios/detail-building" permissions="edificios.list" />} />
        <Route path="detail/:id" element={<RouteLoader view="03_edificios/edificios/detail-building" permissions="edificios.list" />} />
        <Route path="cupos/list" element={<RouteLoader view="03_edificios/cupos/cupos-building" permissions="edificios-cupos.list" />} />
        <Route path="transitos" element={<RouteLoader view="03_edificios/transitos/transitos-building" permissions="edificios-transitos.list" />} />
      </Route>

      <Route path="/accesos">
        <Route path="imports" element={<RouteLoader view="04_accesos/Imports" permissions="imports.show" />} />
        <Route path="vehiculos-inactivos" element={<RouteLoader view="04_accesos/VehiculosInactivos" permissions="vehiculos-inactivos.show" />} />
        <Route path="new" element={<RouteLoader view="04_accesos/AccesosEditCreate" permissions="accesos.create" />} />
        <Route path=":id/:tab" element={<RouteLoader view="04_accesos/AccesosDetail" permissions="accesos.list" />} />
        <Route path=":id" element={<RouteLoader view="04_accesos/AccesosDetail" permissions="accesos.list" />} />
        <Route path="" element={<RouteLoader view="04_accesos/Accesos" permissions="accesos.list" />} />
      </Route>

      <Route path="/config">
        <Route
          path="tipos-matriculas"
          element={<RouteLoader view="05_configuracion/TiposMatriculas/TiposMatriculas" permissions="tipos-matriculas.list" />}
        />

        <Route path="perfiles-acceso">
          <Route
            path="new"
            element={<RouteLoader view="05_configuracion/PerfilesAcceso/PerfilesAccesoEditCreate" permissions="perfiles-acceso.create" />}
          />
          <Route
            path=":id/:tab"
            element={<RouteLoader view="05_configuracion/PerfilesAcceso/PerfilesAccesoDetail" permissions="perfiles-acceso.list" />}
          />
          <Route
            path=":id"
            element={<RouteLoader view="05_configuracion/PerfilesAcceso/PerfilesAccesoDetail" permissions="perfiles-acceso.list" />}
          />
          <Route path="" element={<RouteLoader view="05_configuracion/PerfilesAcceso/PerfilesAcceso" permissions="perfiles-acceso.list" />} />
        </Route>

        <Route path="perfiles-horarios">
          <Route
            path="new"
            element={<RouteLoader view="05_configuracion/PerfilesHorarios/PerfilesHorariosEditCreate" permissions="perfiles-horarios.create" />}
          />
          <Route
            path=":id"
            element={<RouteLoader view="05_configuracion/PerfilesHorarios/PerfilesHorariosDetail" permissions="perfiles-horarios.list" />}
          />
          <Route path="" element={<RouteLoader view="05_configuracion/PerfilesHorarios/PerfilesHorarios" permissions="perfiles-horarios.list" />} />
        </Route>

        <Route path="consola">
          <Route path=":id" element={<RouteLoader view="05_configuracion/Consola/Consola" permissions="consola.show" />} />
          <Route path="" element={<RouteLoader view="05_configuracion/Consola/Consola" permissions="consola.show" />} />
        </Route>
        <Route path="backups" element={<RouteLoader view="05_configuracion/Backups/backups" permissions="backups.list" />} />
        <Route path="auditoria" element={<RouteLoader view="05_configuracion/Auditoria/Auditoria" permissions="auditoria.list" />} />
        <Route path="tareas" element={<RouteLoader view="05_configuracion/Tareas/Tareas" permissions="tareas-programadas.list" />} />
        <Route path="errors" element={<RouteLoader view="05_configuracion/Errores/errores" permissions="frontend-error.list" />} />

        <Route path="roles">
          <Route path=":id" element={<RouteLoader view="05_configuracion/Roles/rol-detail" permissions="roles.update" />} />
          <Route path="" element={<RouteLoader view="05_configuracion/Roles/roles" permissions="roles.list" />} />
        </Route>

        <Route path="ftp" element={<RouteLoader view="05_configuracion/Ftp/Ftp" permissions="ftp.show" />} />

        <Route path="variables">
          <Route path=":tab" element={<RouteLoader view="05_configuracion/Variables/variables" permissions="variables.list" />} />
        </Route>
      </Route>

      <Route path="/cef">
        <Route path="" element={<RouteLoader view="cef/index" layout={EscritorioLayout} permissions="dashboard.show" />} />
      </Route>

      <Route path="/not-found" element={<RouteLoader view="NotFound" layout={LoginLayout} />} />
      <Route path="/not-perms" element={<RouteLoader view="NotPerms" layout={LoginLayout} />} />
      <Route path="/maintenance" element={<RouteLoader view="Maintenance" layout={LoginLayout} />} />
      <Route path="/login" element={<RouteLoader view="login/login" auth={false} layout={LoginLayout} />} />
      <Route path="/password-recovery" element={<RouteLoader view="login/password-recovery" auth={false} layout={LoginLayout} />} />
      <Route path="/password-reset/:token" element={<RouteLoader view="login/password-reset" auth={false} layout={LoginLayout} />} />

      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </RoutesReact>
  );
}

export default Routes;
