// import palette from './palette';

const typography = {
  // fontSize: 12,
  // h1: {
  //   color: palette.text.primary,
  //   fontWeight: 500,
  //   fontSize: '30px',
  //   letterSpacing: '-0.24px',
  //   lineHeight: '20px',
  // },
  // h2: {
  //   color: palette.text.primary,
  //   fontWeight: 500,
  //   fontSize: '29px',
  //   letterSpacing: '-0.24px',
  //   lineHeight: '32px',
  // },
  // h3: {
  //   color: palette.text.primary,
  //   fontWeight: 500,
  //   fontSize: '20px',
  //   letterSpacing: '-0.06px',
  //   lineHeight: '20px',
  // },
  // h4: {
  //   color: palette.text.primary,
  //   fontWeight: 500,
  //   fontSize: '18px',
  //   letterSpacing: '-0.06px',
  //   lineHeight: '18px',
  // },
  // h5: {
  //   color: palette.text.primary,
  //   fontWeight: 500,
  //   fontSize: '16px',
  //   letterSpacing: '-0.05px',
  //   lineHeight: '16px',
  // },
  // h6: {
  //   color: palette.text.primary,
  //   fontWeight: 500,
  //   fontSize: '14px',
  //   letterSpacing: '-0.05px',
  //   lineHeight: '14px',
  // },
  // subtitle1: {
  //   color: palette.text.primary,
  //   fontSize: '16px',
  //   letterSpacing: '-0.05px',
  //   lineHeight: '25px',
  // },
  // subtitle2: {
  //   color: palette.text.secondary,
  //   fontWeight: 400,
  //   fontSize: '14px',
  //   letterSpacing: '-0.05px',
  //   lineHeight: '21px',
  // },
  // body1: {
  //   color: palette.text.primary,
  //   fontSize: '14px',
  //   letterSpacing: '-0.05px',
  //   lineHeight: '21px',
  // },
  // body2: {
  //   color: palette.text.secondary,
  //   fontSize: '12px',
  //   letterSpacing: '-0.04px',
  //   lineHeight: '18px',
  // },
  // button: {
  //   color: palette.text.primary,
  //   fontSize: '12px',
  // },
  // caption: {
  //   color: palette.text.secondary,
  //   fontSize: '10px',
  //   letterSpacing: '0.33px',
  //   lineHeight: '10px',
  // },
  // overline: {
  //   color: palette.text.secondary,
  //   fontSize: '11px',
  //   fontWeight: 500,
  //   letterSpacing: '0.33px',
  //   lineHeight: '13px',
  //   textTransform: 'uppercase',
  // },
};

export default typography;
